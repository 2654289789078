/* eslint-disable react/no-unknown-property */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-unused-vars */
import PropTypes from "prop-types"
import React, { useState } from "react"
import {
  Alert,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import Switch from "react-switch"
import axios from "axios/axios"
import Dropzone from "react-dropzone"

const AddEditCategory = (props) => {
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [selectedFile, setSelectedFile] = useState([])
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: props.category,
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter the name"),
      nameAr: Yup.string().required("Please enter the arabic name"),
    }),
    onSubmit: (values) => {
      handleSubmit(values)
    },
  })

  // function formatBytes(bytes, decimals = 2) {
  //   if (bytes === 0) return "0 Bytes"
  //   const k = 1024
  //   const dm = decimals < 0 ? 0 : decimals
  //   const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  //   const i = Math.floor(Math.log(bytes) / Math.log(k))
  //   return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  // }

  // function handleAcceptedFiles(file) {
  //   Object.assign(file[0], {
  //     preview: URL.createObjectURL(file[0]),
  //     formattedSize: formatBytes(file[0].size),
  //   })

  //   setSelectedFile(file)
  // }

  const handleSubmit = (values) => {
    if (props.selectedCategory) {
      handleEdit(values)
    } else {
      handleAdd(values)
    }
  }

  const handleEdit = (values) => {
    setLoadingSubmit(true)
    props.setError(null)
    axios
      .put(`products/updateCategory/${props.selectedCategory._id}`, values, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        props.setReload((prev) => !prev)
        props.setCategory({
          name: "",
          nameAr: "",
        })
        setSelectedFile([])
        props.setSelectedCategory(null)
      })
      .catch((e) => {
        props.setError(e.response.data?.message)
      })
      .finally(() => {
        setLoadingSubmit(false)
      })
  }

  const handleAdd = (values) => {
    setLoadingSubmit(true)
    props.setError(null)
    axios
      .post("products/addCategory", {name: values.name, nameAr: values.nameAr}, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        props.setReload((prev) => !prev)
        setSelectedFile([])
        props.setCategory({
          name: "",
          nameAr: "",
        })
      })
      .catch((e) => {
        props.setError(e.response.data?.message)
      })
      .finally(() => {
        setLoadingSubmit(false)
      })
  }

  return (
    <>
      <Form
        className="row g-3 needs-validation"
        style={{ width: "100%" }}
        onSubmit={(e) => {
          e.preventDefault()
          validation.handleSubmit()
        }}
      >
        {props.error && <Alert color="danger">{props.error}</Alert>}
        <Row style={{ width: "100%" }}>
        <Col xs={12} md={12}>
            <FormGroup className="mt-3 mb-3">
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                Name
              </Label>
              <Input
                required
                id="name"
                className="form-control"
                type="text"
                placeholder="Name"
                value={props.category.name}
                invalid={validation.touched.name && validation.errors.name}
                onChange={(e) => {
                  props.setCategory({
                    ...props.category,
                    name: e.target.value,
                  })
                  validation.handleChange(e)
                }}
              />
              {validation.touched.name && validation.errors.name}
            </FormGroup>
          </Col>
          <Col xs={12} md={12}>
            <FormGroup className="mt-3 mb-3">
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                {"Name (AR)"}
              </Label>
              <Input
                required
                id="nameAr"
                className="form-control"
                type="text"
                placeholder="nameAr"
                value={props.category.nameAr}
                invalid={validation.touched.nameAr && validation.errors.nameAr}
                onChange={(e) => {
                  props.setCategory({
                    ...props.category,
                    nameAr: e.target.value,
                  })
                  validation.handleChange(e)
                }}
              />
              {validation.touched.nameAr && validation.errors.nameAr}
            </FormGroup>
          </Col>
        </Row>
        <div className="col-12">
          <button
            className="btn btn-primary"
            type={loadingSubmit ? null : "submit"}
          >
            {loadingSubmit ? (
              <Spinner size={"sm"} color="secondary" />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </Form>
    </>
  )
}
AddEditCategory.propTypes = {
  selectedCategory: PropTypes.any,
  setSelectedCategory: PropTypes.any,
  category: PropTypes.any,
  setCategory: PropTypes.any,
  setReload: PropTypes.any,
}

export default AddEditCategory
