import { Alert, Col, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios/axios";
import { v4 as uuidv4 } from "uuid";

const Variants = ({ product, setProduct, editing, show }) => {
  const [error, setError] = useState("");
  const [colors, setColors] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedVarToDelete, setSelectedVarToDelete] = useState(false);
  const [variantEdit, setVariantEdit] = useState(false);
  const [onlyOneVariant, setOnlyOneVariant] = useState(false);
  const initialSelectedVariant = {
    _id: "",
    price: "",
    stock: "",
    volume: "",
    color: "",
  };
  const [selectedVariant, setSelectedVariant] = useState(
    initialSelectedVariant
  );

  const columns = [
    {
      label: "Price",
      field: "price",
      sort: "asc",
    },
    {
      label: "Stock",
      field: "stock",
      sort: "asc",
    },
    {
      label: "Volume",
      field: "volume",
      sort: "asc",
    },
    {
      label: "Color",
      field: "color",
      sort: "asc",
    },
    {
      field: "actions",
    },
  ];

  useEffect(() => {
    axios
      .get("/colors/all")
      .then((res) => setColors(res.data.colors))
      .catch((_) => {});
  }, []);

  const addVariant = (e) => {
    e.preventDefault();
    const checkDuplication = product.variants.findIndex(
      (vr) =>
        vr.color === selectedVariant.color &&
        vr.volume === selectedVariant.volume &&
        vr._id !== selectedVariant._id
    );
    if (checkDuplication !== -1 && product.variants.length !== 0) {
      setError("Variant already exists");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    if (variantEdit) {
      const variantIndex = product.variants.findIndex(
        (vr) => vr._id === selectedVariant._id
      );
      setProduct((prev) => {
        const newVariants = [...prev.variants];
        newVariants[variantIndex] = selectedVariant;
        return {
          ...prev,
          variants: newVariants,
        };
      });
    } else {
      setProduct((prev) => ({
        ...prev,
        variants: [...prev.variants, { ...selectedVariant, _id: uuidv4() }],
      }));
    }
    if (!selectedVariant.color && !selectedVariant.volume)
      setOnlyOneVariant(true);
    else setOnlyOneVariant(false);
    setSelectedVariant(initialSelectedVariant);
    setVariantEdit(false);
  };

  const editBtn = (e, one) => {
    e.preventDefault();
    if (selectedVariant && selectedVariant._id === one._id) {
      setSelectedVariant({
        price: "",
        stock: "",
        volume: "",
      });
      setVariantEdit(false);
    } else {
      setVariantEdit(true);
      setSelectedVariant(one);
    }
  };

  const deleteBtn = (one) => {
    setSelectedVarToDelete(one._id)
    setProduct(prev => ({...prev, variants: prev.variants?.filter(vr => vr._id === one._id)}))
    if (editing) {
      setDeleteLoading(true);
      axios
        .delete(`/products/${product._id}/deleteVariant/${one._id}`, {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        })
        .then((_) => window.location.reload())
        .catch((err) => setError(err.response.data.message || "Something went wrong!"))
        .finally((_) => setDeleteLoading(false));
    }
  };

  return (
    <Row>
      {/* Error */}
      {error && <Alert color="danger">{error}</Alert>}
      {/* Add Edit variants */}
      {(!onlyOneVariant || variantEdit) && !show && (
        <Col md={5}>
          {/* Price */}
          <Col md={12} xs={12}>
            <FormGroup className="mb-3">
              <Label htmlFor="price" style={{ color: "#5B626B" }}>
                Price*
              </Label>
              <Input
                _id="price"
                className="form-control"
                type="number"
                placeholder="Price"
                value={selectedVariant.price}
                onChange={(e) => {
                  setSelectedVariant({
                    ...selectedVariant,
                    price: e.target.value,
                  });
                }}
              />
            </FormGroup>
          </Col>
          {/* Stock */}
          <Col md={12} xs={12}>
            <FormGroup className="mb-3">
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                Stock*
              </Label>
              <Input
                _id="stock"
                className="form-control"
                type="number"
                placeholder="Stock"
                value={selectedVariant.stock}
                onChange={(e) => {
                  setSelectedVariant({
                    ...selectedVariant,
                    stock: e.target.value,
                  });
                }}
              />
            </FormGroup>
          </Col>
          {/* Color */}
          <Col md={12} xs={12}>
            <FormGroup className="mb-3">
              <Label htmlFor="color" style={{ color: "#5B626B" }}>
                Color
              </Label>
              <Input
                _id="color"
                className="form-control"
                type="select"
                placeholder="Color"
                value={selectedVariant.color?._id || ''}
                onChange={(e) => {
                  setSelectedVariant(prev => ({
                    ...prev,
                    color: {_id: e.target.value},
                  }));
                }}
              >
                <option value="" label="Select color" disabled />
                {colors &&
                  Array.isArray(colors) &&
                  colors.length > 0 &&
                  colors.map((clr, ind) => (
                    <option key={ind} value={clr._id}>
                      <div>{clr.name}</div>
                      <div>{"  -  "}</div>
                      <div>{clr.nameAr}</div>
                    </option>
                  ))}
              </Input>
            </FormGroup>{" "}
          </Col>
          {/* Volume */}
          <Col md={12} xs={12}>
            {/* {true ? ( */}
            <FormGroup className="mb-3">
              <Label htmlFor="volume" style={{ color: "#5B626B" }}>
                Volume (ml)
              </Label>
              <Input
                _id="volume"
                className="form-control"
                type="select"
                placeholder="volume"
                value={selectedVariant.volume}
                onChange={(e) => {
                  setSelectedVariant({
                    ...selectedVariant,
                    volume: e.target.value,
                  });
                }}
              >
                <option value="" label="Select volume" disabled />
                <option value={50} label="50" />
                <option value={75} label="75" />
                <option value={100} label="100" />
                <option value={125} label="125" />
                <option value={150} label="150" />
                <option value={200} label="200" />
                <option value={220} label="220" />
                <option value={250} label="250" />
                <option value={300} label="300" />
                <option value={400} label="400" />
                <option value={500} label="500" />
              </Input>
            </FormGroup>{" "}
          </Col>
          <button
            disabled={!selectedVariant.price || !selectedVariant.stock}
            onClick={addVariant}
            style={{ float: "right" }}
            className="btn btn-primary"
            type={"submit"}
          >
            {variantEdit ? (
              <i className="fas fa-edit"></i>
            ) : (
              <i className="fas fa-plus"></i>
            )}
          </button>
        </Col>
      )}
      {/* Table */}
      <Col md={(!onlyOneVariant || variantEdit) && !show ? 7 : 12}>
        <MDBDataTable
          hover
          responsive
          noBottomColumns
          data={{
            columns: columns,
            rows: product.variants?.map((one) => {
              return {
                ...one,
                color: one.color?.name || colors.find((clr) => clr._id === one.color?._id )?.name || "-",
                volume: one.volume || "-",
                actions: !show &&(
                  <Row>
                    <Col md={6} className="m-0 p-0">
                      <Link
                        to="#"
                        className="btn btn-primary btn-sm m-0"
                        onClick={(e) => editBtn(e, one)}
                      >
                        {variantEdit &&
                        selectedVariant &&
                        selectedVariant._id === one._id ? (
                          <i
                            className="ion ion-md-close"
                            style={{
                              color: "white",
                            }}
                          ></i>
                        ) : (
                          <i className="fas fa-edit"></i>
                        )}
                      </Link>
                    </Col>
                    <Col md={6} className="m-0 p-0">
                      <Link
                        to="#"
                        className="btn btn-danger btn-sm"
                        onClick={(_) => deleteBtn(one)}
                      >
                        {
                          deleteLoading && selectedVarToDelete === one._id ?
                          <Spinner size={"sm"}/>
                          : <i className="fas fa-trash"></i>
                        }
                      </Link>
                    </Col>
                  </Row>
                ),
              };
            }),
          }}
        />
      </Col>
    </Row>
  );
};

export default Variants;
