/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs*/
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Alert,
  Spinner,
  Label,
} from "reactstrap";

import { Link } from "react-router-dom";

import "chartist/dist/scss/chartist.scss";

//i18n
import { withTranslation } from "react-i18next";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormGroup,
  Input,
} from "@material-ui/core";
import axios from "axios/axios.js";
import { MDBDataTable } from "mdbreact";
import { productsData } from "common/data/ecommerce";

const Products = () => {
  const columns = [
    {
      label: "Variant Name(EN)",
      field: "name",
      sort: "asc",
    },
    {
      label: "Variant Name(ar)",
      field: "nameAr",
      sort: "asc",
    },
    {
      label: "Price",
      field: "price",
      sort: "asc",
    },
    {
      label: "Stock",
      field: "stock",
      sort: "asc",
    },
    {
      label: "Volume",
      field: "volume",
      sort: "asc",
    },
    {
      label: "Color",
      field: "color",
      sort: "asc",
    },
    {
      label: "Sale %",
      field: "salePercentage",
      sort: "asc",
    },
    {
      label: "Sale Value",
      field: "saleValue",
      sort: "asc",
    },
    {
      label: "Sale Price",
      field: "afterSale",
      sort: "asc",
    },
    {
      label: "",
      field: "actions",
    },
  ];

  const [addSaleLoading, setAddSaleLoading] = useState(false);
  const [removeSaleLoading, setRemoveSaleLoading] = useState(false);
  const [removeSaleId, setRemoveSaleId] = useState(null);
  const [tableLoading, setTableLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [salePercentage, setSalePercentage] = useState(null);
  const [checkedIds, setCheckedIds] = useState([]);
  const [Error, setError] = useState("");

  useEffect(() => {
    setTableLoading(true);
    axios
      .get("products/all", {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        const variants = res.data.products
          ?.map((prod) => {
            return prod.variants?.map((vr) => ({
              _id: vr._id,
              product_id: prod._id,
              name: prod.name,
              nameAr: prod.nameAr,
              price:
                Number(vr.price) +
                Number(vr.isSale ? vr.saleValue.toFixed(2) : 0),
              stock: vr.stock,
              volume: vr.volume ? vr.volume : "-",
              color: vr.color ? vr.color.name : "-",
              isSale: vr.isSale,
              salePercentage: vr.salePercentage
                ? vr.salePercentage + "%"
                : "None",
              afterSale: vr.price,
              saleValue: vr.saleValue ? vr.saleValue : "-",
              isAvailable: vr.isAvailable,
            }));
          })
          .flat();
        setProducts(variants);
      })
      .catch((_) => setError("Something went wrong!"))
      .finally((_) => setTableLoading(false));
  }, []);

  const submitSale = () => {
    setAddSaleLoading(true);
    axios
      .patch(
        "products/addSale",
        { variants: checkedIds, salePercentage },
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        }
      )
      .then((_) => window.location.reload())
      .catch((err) => setError(err.response.data?.message))
      .finally((_) => setAddSaleLoading(false));
  };

  const switchCheckVariant = (e, one, index) => {
    e.preventDefault();
    if (!one.checked) {
      setProducts((prev) => {
        const updatedData = [...prev];
        updatedData[index].checked = true;
        return updatedData;
      });
      setCheckedIds((prev) => [...prev, one._id]);
    } else {
      setProducts((prev) => {
        const updatedData = [...prev];
        updatedData[index].checked = false;
        return updatedData;
      });
      setCheckedIds((prev) => prev.filter((ele) => ele !== one._id));
    }
  };

  const removeVariantSale = (e, one, index) => {
    e.preventDefault();
    setRemoveSaleId(one._id);
    setRemoveSaleLoading(true);
    axios
      .patch(
        `products/removeSale/${one.product_id}`,
        { variantId: one._id },
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        }
      )
      .then((_) => window.location.reload())
      .catch((err) => setError(err.response.data?.message))
      .finally((_) => setRemoveSaleLoading(false));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h5 className="page-title">Sale</h5>
              </Col>
            </Row>
          </div>
          {/* ========= Product List ========= */}
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <h6 style={{ fontWeight: "bold" }}>Variants List</h6>
                </CardHeader>
                <CardBody>
                  {/* Table Loading */}
                  {tableLoading && <Spinner />}
                  {/* Error */}
                  {Error && <Alert color="danger">{Error}</Alert>}
                  {/* Sale Input */}
                  <Row className="mb-4">
                    <Col md={4} xs={8}>
                      <FormGroup>
                        <Label
                          htmlFor="Percentage"
                          style={{ color: "#5B626B" }}
                        >
                          Sale
                        </Label>
                        <Input
                          _id="Percentage"
                          className="form-control"
                          type="number"
                          placeholder="Sale percentage"
                          value={salePercentage}
                          onChange={(e) => setSalePercentage(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2} xs={4} className="d-flex align-items-end">
                      <button
                        type="button"
                        className="btn btn-primary py-2 px-4"
                        onClick={submitSale}
                      >
                        {addSaleLoading ? <Spinner size={"sm"} /> : "Submit"}
                      </button>
                    </Col>
                  </Row>
                  {/* Table */}
                  <MDBDataTable
                    hover
                    responsive
                    noBottomColumns
                    data={{
                      columns: columns,
                      rows: products.map((one, index) => {
                        return {
                          ...one,
                          actions: (
                            <Row>
                              {" "}
                              <Col md={6}>
                                <Link
                                  to="#"
                                  className={
                                    "btn btn-sm " +
                                    (one.isSale
                                      ? "btn-danger"
                                      : one.checked
                                      ? "btn-success"
                                      : "btn-info")
                                  }
                                  onClick={
                                    one.isSale
                                      ? (e) => removeVariantSale(e, one, index)
                                      : (e) => switchCheckVariant(e, one, index)
                                  }
                                >
                                  {one.isSale ? (
                                    removeSaleId === one._id &&
                                    removeSaleLoading ? (
                                      <Spinner size={"sm"} />
                                    ) : (
                                      <i className="fas fa-close"></i>
                                    )
                                  ) : one.checked ? (
                                    <i className="fas fa-check"></i>
                                  ) : (
                                    <i className="fa-regular fa-square"></i>
                                  )}
                                </Link>
                              </Col>
                            </Row>
                          ),
                        };
                      }),
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Products.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Products);
